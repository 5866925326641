import { Box, Button, Group, LoadingOverlay, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthApi } from "../apis";
import { authChange, authMe } from "../store/auth";
import { message } from "../utils/message";
import { Form } from "./form";
import { PasswordField } from "./form/password-field";
import { TextField } from "./form/text-field";

type IForm = {
  code: string | undefined;
  username: string | undefined;
  password: string | undefined;
};

const schema = yup.object({
  code: yup.string().required("Кодоо оруулна уу").trim(),
  username: yup.string().required("Нэвтрэх нэр оруулна уу").trim().lowercase(),
  password: yup.string().required("Нууц үг оруулна уу"),
});

const LoginForm = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const [data] = React.useState<IForm>({
    code: undefined,
    username: undefined,
    password: undefined,
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const res = await AuthApi.login(values);
      dispatch(authChange(res));

      const auth = await AuthApi.me();
      dispatch(authMe(auth));

      navigate("/product");
      message.success("Амжилттай нэвтэрлээ");
      setLoading(false);
    } catch (error: any) {
      error?.message && message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <Box mx="auto" className={classes.container}>
      <LoadingOverlay visible={loading} />
      <Form onSubmit={onSubmit} initialValues={data} validationSchema={schema}>
        {({ values }) => {
          return (
            <>
              <TextField label="Нэвтрэх код оруулна уу:" size="md" variant="unstyled" placeholder="Нэвтрэх код" className={classes.input} name="code" />

              <TextField
                label="Хэрэглэгчийн нэрээ оруулна уу:"
                size="md"
                variant="unstyled"
                placeholder="Хэрэглэгчийн нэр"
                className={classes.input}
                name="username"
              />
              <PasswordField
                styles={{
                  rightSection: {
                    position: "absolute",
                    backgroundColor: "#00000050",
                    borderBottomRightRadius: 4,
                    borderTopRightRadius: 4,
                    height: 40,
                  },
                }}
                size="md"
                label="Нууц үг оруулах:"
                variant="unstyled"
                placeholder="Нууц үг"
                className={classes.input}
                name="password"
              />
              <Group align="right">
                <div className={classes.forgotText}>
                  Нууц үг ээ мартсан бол
                  <Link to={"/forgot"}>
                    <Text span c="blue" inherit className={classes.highLightText}>
                      ЭНД ДАРЖ
                    </Text>
                  </Link>
                  сэргээнэ үү.
                </div>
              </Group>

              <Group align="right" my={22}>
                <Button variant="gradient" gradient={{ from: "red", to: "#051139" }} size="md" w="100%" type="submit">
                  Нэвтрэх
                </Button>
              </Group>

              <Group align="right">
                <div className={classes.forgotText}>
                  Байгууллага бүртгэлгүй бол
                  <UnstyledButton
                    onClick={() => {
                      window.location.href = "https://dev-partner.dehub.mn/register";
                    }}>
                    <Text span c="blue" inherit className={classes.highLightText}>
                      ЭНД ДАРЖ
                    </Text>
                  </UnstyledButton>
                  бүртгүүлнэ үү.
                </div>
              </Group>
            </>
          );
        }}
      </Form>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
  },
  labelText: {
    color: theme.white,
    fontWeight: 400,
    fontSize: 16,
    marginTop: 20,
  },

  input: {
    input: {
      backgroundColor: "#00000050",
      paddingInline: 10,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,

      "&:focus-within": {
        color: theme.white,
      },
      color: theme.white,
    },
    label: {
      color: theme.white,
    },
    borderRadius: 4,
    marginTop: 15,
    color: theme.white,
    alignItems: "center",
    height: 80,
  },

  forgotText: {
    color: "white",
    marginTop: 10,
  },
  highLightText: {
    fontWeight: 700,
    marginLeft: 10,
    marginRight: 10,
  },
}));

export default LoginForm;
